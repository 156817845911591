import {Nullable} from "../common/types";

export class Campaign {
    id: string;
    short_link: string;
    description: Nullable<string>;
    translations: CampaignTranslations;
    images: CampaignImages;
    donation_targets: CampaignDonationTargets;
    payment_options: Array<PaymentOption>;
    max_donation_amount: Nullable<number>;
    payment_providers: Array<PaymentProvider>;

    donations_enabled: boolean;
    preview_mode: boolean;
    currency: string;

    created_at: string;

    donation_amount: number;
    donation_amount_secondary_currency: number;

    secondary_currency: Nullable<string>;
    secondary_currency_goal: Nullable<number>;
    locals_currency: Nullable<string>;
    locals_country_code: Nullable<string>;
    show_multiple_payment_providers: Nullable<string>;
    currency_conversion_rate: Nullable<number>;

    constructor(campaign: any) {
        Object.assign(this, campaign)
        if (this.preview_mode) {
            this.translations.ar.title = `${this.translations.ar.title} (Preview Mode)`
            this.translations.he.title = `${this.translations.he.title} (Preview Mode)`
            this.translations.en.title = `${this.translations.en.title} (Preview Mode)`
        }
    }

    getCreatedAt(): Date {
        return new Date(this.created_at);
    }

    getHeaderImg() {
        return this.images.header_img_url || 'assets/images/no-image.png'
    }

    getFeaturedImg() {
        return this.images.logo_img_url || 'assets/images/logos/logo-icon-light-w-bg.jpg'
    }

    getCustomPaymentOption() {
        return this.payment_options.find(p => p.is_custom)
    }

    getNonCustomPaymentOptions() {
        return this.payment_options.filter(p => !p.is_custom)
            .sort((a, b) => a.amount! - b.amount!)
    }

    // getAmbassadorDonations(ambassador: Ambassador): Array<Donation> {
    //     return this.donations.filter(d => d.ambassador_id == ambassador.id)
    // }

    shouldShowSecondaryCurrency(country: string): boolean {
        return this.donation_amount_secondary_currency != null &&
            this.locals_currency != null &&
            this.locals_country_code != null &&
            this.locals_currency == this.secondary_currency &&
            country?.toLowerCase() == this.locals_country_code?.toLowerCase()
    }

    getDonationsAmountByCountry(country: string) {
        if (this.shouldShowSecondaryCurrency(country)) {
            return this.donation_amount_secondary_currency
        }

        return this.donation_amount
    }

    getGoalByCountry(country: string) {
        if (this.shouldShowSecondaryCurrency(country)) {
            return this.secondary_currency_goal
        }

        return this.donation_targets.amount
    }

    getCurrencyByCountry(country: string) {
        if (this.shouldShowSecondaryCurrency(country)) {
            return this.locals_currency!!
        }

        return this.currency
    }
}

export class MinimizedCampaign {
    id: string;
    short_link: string;
    translations: CampaignTranslations;
    images: CampaignImages;
    donation_amount: number;
    donation_amount_secondary_currency: number;
    donation_targets: CampaignDonationTargets;

    donations_enabled: boolean;
    preview_mode: boolean;
    currency: string;

    secondary_currency: Nullable<string>;
    secondary_currency_goal: Nullable<number>;
    locals_currency: Nullable<string>;
    locals_country_code: Nullable<string>;
    show_multiple_payment_providers: Nullable<string>;
    currency_conversion_rate: Nullable<number>;

    created_at: string;

    constructor(campaign: any) {
        Object.assign(this, campaign)
        if (this.preview_mode) {
            this.translations.ar.title = `${this.translations.ar.title} (Preview Mode)`
            this.translations.he.title = `${this.translations.he.title} (Preview Mode)`
            this.translations.en.title = `${this.translations.en.title} (Preview Mode)`
        }
    }

    shouldShowSecondaryCurrency(country: string) {
        return this.donation_amount_secondary_currency &&
            this.locals_currency &&
            this.locals_country_code &&
            this.locals_currency == this.secondary_currency &&
            country?.toLowerCase() == this.locals_country_code?.toLowerCase()
    }

    getDonationsAmountByCountry(country: string) {
        if (this.shouldShowSecondaryCurrency(country)) {
            return this.donation_amount_secondary_currency
        }

        return this.donation_amount
    }

    getGoalByCountry(country: string) {
        if (this.shouldShowSecondaryCurrency(country)) {
            return this.secondary_currency_goal
        }

        return this.donation_targets.amount
    }

    getCurrencyByCountry(country: string) {
        if (this.shouldShowSecondaryCurrency(country)) {
            return this.locals_currency!!
        }

        return this.currency
    }

    getCreatedAt(): Date {
        return new Date(this.created_at);
    }

    getFeaturedImg() {
        return this.images.logo_img_url || 'assets/images/logos/logo-icon-light-w-bg.jpg'
    }
}

export interface CampaignImages {
    header_img_url: Nullable<string>;
    logo_img_url: Nullable<string>;
}

export interface CampaignTranslations {
    ar: CampaignTranslation;
    he: CampaignTranslation;
    en: CampaignTranslation;
}

export type CampaignTranslationsKey = 'ar' | 'he' | 'en';

export interface CampaignTranslation {
    title: string;
    sub_title: string;
    about_us: string;
}

export interface CampaignDonationTargets {
    amount: number;
    date: Date;
}

export class Donation {
    ambassador_id: Nullable<number>;
    amount: number;
    date: Date;
    donor_name: Nullable<string>;
    donor_profile_image_url: Nullable<string>;
    message: Nullable<string>;
    currency: string;

    constructor(donation: any) {
        Object.assign(this, donation)
        this.date = new Date(donation.date)
    }

    getDonorImage() {
        return this.donor_profile_image_url || 'assets/images/user-no-face.jpg'
    }
}

export class Ambassador {
    id: number;
    name: string;
    profile_picture_url: Nullable<string>;
    donations_target: Nullable<number>;
    donations_target_in_secondary_currency: Nullable<number>;
    donations_amount: number;
    donations_amount_in_secondary_currency: number;

    constructor(ambassador: any) {
        Object.assign(this, ambassador)

    }

    getProfilePicture() {
        return this.profile_picture_url || 'assets/images/user-no-face.jpg'
    }
}

export interface PaymentOption {
    id: number;
    image_url: Nullable<string>;
    is_custom: Nullable<boolean>;
    amount: Nullable<number>;
    selected: Nullable<boolean>;
}

export interface PaymentProvider {
    id: string,
    type: string,
    client_id: string
}
